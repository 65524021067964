

.parsedown h1{
    @apply mt-8 text-3xl font-bold font-exo
}
.parsedown h2{
    @apply mt-4 text-2xl font-bold font-exo
}

.parsedown h3{
    @apply mt-4 text-lg font-bold font-exo
}
.parsedown p{
    @apply mt-2 text-base leading-relaxed
}

.parsedown p code{
    @apply bg-gray-200 p-1 px-2 rounded mr-1
}
.parsedown pre{
    @apply overflow-x-auto bg-gray-200 p-2 rounded mt-3 py-2
}

.link-styles a{
    @apply text-blue-500
}
.link-styles a:hover{
    @apply text-blue-700
}

.parsedown a {
    @apply text-blue-500
}
.parsedown a:hover {
    @apply text-blue-700
}

.parsedown ul{
    @apply list-disc list-inside ml-4
}