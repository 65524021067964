$dark: #303956;
$primary: #2575dc;



@font-face {
    font-family: 'Exo 2';
    src: url('../fonts/exo2-regular-webfont.woff2') format('woff2'),
    url('../fonts/exo2-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('../fonts/exo2-semibold-webfont.woff2') format('woff2'),
    url('../fonts/exo2-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('../fonts/exo2-light-webfont.woff2') format('woff2'),
    url('../fonts/exo2-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.font-exo {
    font-family: 'Exo 2', sans-serif;
}

a.tox-promotion-link {
    display: none !important;
}

@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
@import "parsedown";
@import "blog-styles";